import onmount from 'onmount'

onmount('[data-component=alert]', function () {
  const closeButton = this.querySelector('[data-action=close]')

  if (closeButton) {
    closeButton.addEventListener('click', () => {
      this.remove()
    })
  }
})
