import get from 'lodash/get'
import onmount from 'onmount'

const LEVELS = {
  none: { class: '-invisible' },
  weak: { class: '-weak', text: 'Weak' },
  soso: { class: '-soso', text: 'So-so' },
  good: { class: '-good', text: 'Good' },
  great: { class: '-great', text: 'Great!' }
}

onmount('[data-component=password-strength-meter]', function () {
  const meter = this
  const strengthDisplay = meter.querySelector('[data-show=strength-level]')
  const warningDisplay = meter.querySelector('[data-show=warning]')
  const suggestionsDisplay = meter.querySelector('[data-show=suggestions]')
  const passwordInput = document.querySelector('input[type=password]')

  const setLevel = function (level) {
    meter.classList.remove('-invisible', '-weak', '-soso', '-good', '-great')
    meter.classList.add(level.class)
    strengthDisplay.innerText = level.text
  }

  import('zxcvbn').then(zxcvbn => {
    passwordInput.addEventListener('input', () => {
      const password = passwordInput.value
      const result = password && zxcvbn(password)

      if (!result) {
        setLevel(LEVELS.none)
      } else if (result.score <= 1) {
        setLevel(LEVELS.weak)
      } else if (result.score <= 2) {
        setLevel(LEVELS.soso)
      } else if (result.score <= 3) {
        setLevel(LEVELS.good)
      } else {
        setLevel(LEVELS.great)
      }

      warningDisplay.innerText = get(result, 'feedback.warning', '')
      suggestionsDisplay.innerText = get(result, 'feedback.suggestions', []).join(' ')
    })
  })
})
