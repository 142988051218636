import 'stylesheets/sessions.scss'

import onmount from 'onmount'
import { mount as mountUniversalHeader } from 'universal-header'

import '../sessions/behaviors/alert'
import '../sessions/behaviors/password-strength-meter'

onmount('.universal-header', () => mountUniversalHeader())
onmount()
